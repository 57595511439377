body {
  font-family: "Montserrat", sans-serif;
}

.font-montserrat-thin {
  font-family: "Montserrat Thin", sans-serif;
}

.font-montserrat-extra-light {
  font-family: "Montserrat Extra Light", sans-serif;
}

.font-montserrat-light {
  font-family: "Montserrat Light", sans-serif;
}

.font-montserrat {
  font-family: "Montserrat", sans-serif;
}

.font-montserrat-medium {
  font-family: "Montserrat Medium", sans-serif;
}

.font-montserrat-semi-bold {
  font-family: "Montserrat Semi Bold", sans-serif;
}

.font-montserrat-bold {
  font-family: "Montserrat Bold", sans-serif;
}

.font-montserrat-extra-bold {
  font-family: "Montserrat Extra Bold", sans-serif;
}

.font-montserrat-black {
  font-family: "Montserrat Black", sans-serif;
}

.font-homemade-apple {
  font-family: "Homemade Apple", cursive;
}

@font-face {
  font-family: Montserrat Thin;
  font-style: normal;
  font-weight: 100;
  src: local("Montserrat Thin"),
  url("./assets/fonts/Montserrat-Thin.ttf") format("ttf");
}

@font-face {
  font-family: Montserrat Extra Light;
  font-style: normal;
  font-weight: 200;
  src: local("Montserrat Extra Light"),
  url("./assets/fonts/Montserrat-ExtraLight.ttf") format("ttf");
}

@font-face {
  font-family: Montserrat Light;
  font-style: normal;
  font-weight: 300;
  src: local("Montserrat Light"),
  url("./assets/fonts/Montserrat-Light.ttf") format("ttf");
}
@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  src: local("Montserrat"),
  url("./assets/fonts/Montserrat-Regular.ttf") format("ttf");
}

@font-face {
  font-family: Montserrat Medium;
  font-style: normal;
  font-weight: 500;
  src: local("Montserrat Medium"),
  url("./assets/fonts/Montserrat-Medium.ttf") format("ttf");
}

@font-face {
  font-family: Montserrat Semi Bold;
  font-style: normal;
  font-weight: 600;
  src: local("Montserrat Semi Bold"),
  url("./assets/fonts/Montserrat-SemiBold.ttf") format("ttf");
}

@font-face {
  font-family: Montserrat Bold;
  font-style: normal;
  font-weight: 700;
  src: local("Montserrat Bold"),
  url("./assets/fonts/Montserrat-Bold.ttf") format("ttf");
}

@font-face {
  font-family: Montserrat Extra Bold;
  font-style: normal;
  font-weight: 800;
  src: local("Montserrat Extra Bold"),
  url("./assets/fonts/Montserrat-ExtraBold.ttf") format("ttf");
}

@font-face {
  font-family: Montserrat Black;
  font-style: normal;
  font-weight: 900;
  src: local("Montserrat Black"),
  url("./assets/fonts/Montserrat-Black.ttf") format("ttf");
}

@font-face {
  font-family: Homemade Apple;
  font-style: normal;
  font-weight: 500;
  src: local("Homemade Apple"),
  url("./assets/fonts/HomemadeApple.ttf") format("ttf");
}

/*thin - 100*/
/*ExtraLight - 200*/
/*light - 300*/
/*regular - 400*/
/*medium - 500*/
/*semiBold - 600*/
/*bold - 700*/
/*extraBold - 800*/
/*black - 900*/
